import * as React from 'react'
import { useState } from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { graphql, useStaticQuery } from 'gatsby'
import NewArrivalSlider from '../components/sliders/NewArrivalSlider'
import { IconSearchDL } from '../components/Icons'
import BlogCardGrid from '../components/BlogCardGrid'
import useInfinteScroll from '../hooks/useInfiniteScroll'
import useUrlState from '../hooks/useUrlState'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'

const BlogPage = () => {
  const pageSize = 24
  const [limit, setLimit] = useState(pageSize)

  const [keyword, setKeyword] = useUrlState('q', '')
  const loadMore = React.useCallback(() => {
    setLimit(limit => limit + pageSize)
  }, [setLimit])
  const nodeRef = useInfinteScroll(limit, loadMore)

  const { blogs } = useStaticQuery(
    graphql`
      query {
        blogs: allStrapiBlogPost(sort: { order: DESC, fields: publishedAt }) {
          nodes {
            id
            title
            seo {
              metaTitle
              metaDescription
            }
            url: urlPath
            seo {
              metaTitle
              metaDescription
              metaImage {
                gatsbyImageDataMock
              }
            }
            heroImage {
              gatsbyImageDataMock
            }
            createdAt
            updatedAt
          }
        }
      }
    `
  )
  blogs.nodes.forEach(blog => {
    blog?.seo?.metaImage && processGatsbyImageDataMock(blog?.seo?.metaImage)
    blog?.heroImage && processGatsbyImageDataMock(blog.heroImage)
  })

  const filteredBlogs = blogs.nodes.filter(
    b => b.title.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
  )

  return (
    <Layout breadcrumbs={true} className="mx-auto" canonical={'/blog/'} title={'Blogs'}>
      <Seo
        title={' Gray & Sons | Luxury Watches and Jewelry Blogs'}
        canonical={'/blog/'}
        description={
          'Sell your Watches and Jewelry for the most money. Gray and Sons | Jewelry & Watch Specialists Blog.'
        }
      />

      <section className="w-10/12 max-w-[1366px] mx-auto flex flex-col items-center justify-center py-5 gap-3">
        <h1 className="text-center text-lg font-bold uppercase md:text-2xl xl:text-3xl">
          PRE-owned luxury watches and estate jewelry blogs
        </h1>
        <h2 className="text-center lg:text-lg uppercase xl:text-xl">
          Educational and informative blogs about fine watches, designer and jewelry.
        </h2>
        <form
          className="justify-self-end hidden w-full grow min-w-max max-w-[700px] md:block mx-auto"
          action={'/blog/'}
          method={'get'}
          onSubmit={e => e.preventDefault()}
        >
          <div
            className={
              'hidden border w-full mx-auto border-gray-300 rounded-full md:flex flex-row hover:shadow-lg focus:shadow-lg my-3 duration-300'
            }
          >
            <input
              type={'text'}
              name={'q'}
              placeholder={'Search'}
              aria-label="Search Query"
              value={keyword}
              id={'search-keywords'}
              onChange={e => {
                setKeyword(e.target.value)
              }}
              className={' py-3 px-5 w-full rounded-l-full  placeholder-gray-500 outline-0'}
            />
            <button className={'text-gray-500 h-10 my-auto px-5 border-l border-gray-300 '}>
              <IconSearchDL />
              <span className={'sr-only'}>Search</span>
            </button>
          </div>
        </form>
      </section>
      <section className={'w-10/12 max-w-[1366px] mx-auto'}>
        <BlogCardGrid blogs={filteredBlogs.slice(0, pageSize)} />
        <NewArrivalSlider />
        {limit > pageSize && (
          <BlogCardGrid blogs={filteredBlogs.slice(pageSize, limit - pageSize)} />
        )}
        {limit < filteredBlogs.length && (
          <button
            ref={nodeRef}
            className={'btn-cta p-4 mx-auto grid col-span-1 md:col-span-2 lg:col-span-3 '}
            onClick={loadMore}
          >
            Load More Posts
          </button>
        )}
      </section>
    </Layout>
  )
}

export default BlogPage
