import * as React from 'react'
import BlogCard from './BlogCard'
import PropTypes from 'prop-types'

const BlogCardGrid = ({ blogs }) => {
  return (
    <section className="mx-auto 2xl:px-0 gap-5 justify-center items-center max-w-[1366px] grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {(blogs || []).map(blog => (
        <BlogCard key={blog.id} blog={blog} />
      ))}
    </section>
  )
}

BlogCardGrid.propTypes = {
  blogs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      title: PropTypes.string,
      seo: PropTypes.shape({
        metaTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        metaImage: PropTypes.shape({
          gatsbyImageData: PropTypes.object,
        }),
      }),
      heroImage: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }),
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    })
  ),
}

export default BlogCardGrid
