import * as React from 'react'
import { getImage, StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const HeroImageUrl = ({ image, alt, className }) => {
  return image ? (
    <GatsbyImage
      objectFit="contain"
      image={getImage(image.gatsbyImageData)}
      alt={alt}
      className={className}
    />
  ) : (
    <StaticImage
      src={'../images/top-watch-buyer-img.png'}
      alt={alt}
      className={
        'mx-auto aspect-square rounded-2xl object-contain duration-200 group-hover:scale-[102%]'
      }
    />
  )
}

const BlogCard = ({ blog }) => {
  return (
    <Link
      to={blog.url}
      className=" group flex w-full flex-col space-y-2 p-4 border rounded-lg shadow-md duration-200  ease-in-out hover:shadow-lg md:hover:-translate-y-1"
    >
      <HeroImageUrl
        alt={blog.title + ' image'}
        image={blog.heroImage || blog.seo?.metaImage}
        className="w-full aspect-square  duration-200  group-hover:scale-[102%]"
      />
      <h2 className="h-14 text-xl font-bold line-clamp-2">{blog.title}</h2>
      <p className="h-24 line-clamp-4">{blog.seo?.metaDescription}</p>
      <b
        role="presentation"
        className="cursor-pointer text-left text-gray-700 group-hover:text-red-600 font-normal hover:font-bold"
      >
        Read More <span className="sr-only">About {blog.title}</span>
      </b>
    </Link>
  )
}

HeroImageUrl.propTypes = {
  image: PropTypes.shape({ gatsbyImageData: PropTypes.object }),
  alt: PropTypes.string,
  className: PropTypes.string,
}

BlogCard.propTypes = {
  blog: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    title: PropTypes.string,
    seo: PropTypes.shape({
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.string,
      metaImage: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }),
    }),
    heroImage: PropTypes.shape({
      gatsbyImageData: PropTypes.object,
    }),
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
}

export default BlogCard
